import Vue from 'vue'
import App from './App.vue'
import store from './store';
import VueMask from 'v-mask';
import Vuelidate from 'vuelidate';
import VueRouter from 'vue-router';
import vueDebounce from 'vue-debounce'
import router from './router';
Vue.use(VueRouter);

Vue.config.productionTip = false;

Vue.use(vueDebounce, {
    listenTo: 'input'
    })

Vue.use(Vuelidate);
Vue.use(VueMask);
new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app')
