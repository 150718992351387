import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import appConfig from "@/appConfig";
import Utils from "@/helpers/Utils";
import router from '@/router/index';
const url = new URL(document.location.href);
const searchParams = new URLSearchParams(url.search);
const initTransfer = searchParams.get('test') === 'true' ? 'initTransfer?test=true' : 'initTransfer';

Vue.use(Vuex);
axios.defaults.withCredentials = true;
let stopRequest = false;
export default new Vuex.Store({
    state: {
        showLoader: false,
        paymentId: null,
        showFrame: false,
        disabledButton: false,
        validationError:'',
        fee: '',

    }
    ,
    mutations: {
        updateStateLoader(state, loaderState){
            state.showLoader = loaderState;
        },

        updatePaymentId(state, id) {
            state.paymentId = id;
        },

        updateShowFrame(state, frame) {
            state.showFrame = frame;
        },

        updateDisabledButton(state, btn) {
            state.disabledButton = btn;
        },

        updateValidationError(state, error) {
            state.validationError = error;
        },

        updateFee(state, fee) {
            state.fee = fee;
        }
    },
    actions: {

        async initSaleOrGetFeeFetch( {commit, dispatch},[data, url = initTransfer]) {
            let response = await fetch(appConfig.prefix + url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json;charset=utf-8'
                },
                credentials: 'include',
                body: data
            })


            response = await response.json();
            if(response.status === appConfig.AWAITING_PROCESSING) {
                dispatch('statusPoll')
            }

            if(response.status === appConfig.VALIDATION_ERROR) {
                stopRequest = true;
                commit('updateStateLoader', false);
                commit('updateDisabledButton', false);
                commit('updateValidationError', 'Проверьте правильность данных')

            }

            if(response.status === 'success' && response.fee) {
                commit('updateFee', response.fee);
            }

        },

        async initSaleOrGetFee({dispatch, commit}, [data, url = initTransfer]) {
            commit('updateDisabledButton', true);
            await axios.request({
                method: 'POST',
                url: appConfig.prefix + url,
                responseType: 'json',
                data: data,
                withCredentials: true,
                credentials: 'include',
                headers: {'Access-Control-Allow-Origin': '*', 'Content-Type': 'application/json'}
            }).then(response => {

                if(response.data && response.data.status === appConfig.PURCHASE_ERROR) {
                    commit('updateShowFrame', false);
                    commit('updateStateLoader', false);
                    commit('updateDisabledButton', false);
                    commit('updatePaymentId', null);
                    router.push({
                        name:'error'
                    })
                }

                if(response.data && response.data.status === appConfig.AWAITING_PROCESSING) {
                    dispatch('statusPoll')
                }

                if(response.data && response.data.status === appConfig.VALIDATION_ERROR) {
                    stopRequest = true;
                    commit('updateStateLoader', false);
                    commit('updateDisabledButton', false);
                    commit('updateValidationError', 'Проверьте правильность данных')

                }

                if(response.data && response.data.status === 'success' && response.data.fee) {
                    commit('updateFee', response.data.fee);
                    commit('updateDisabledButton', false);
                }

            })
                .catch((error)=> {
                    console.error(error.response.data);
                });
        },

        setStateLoader({commit}, loaderState) {
            commit('updateStateLoader', loaderState);
        },

        setPaymentId({commit}, id) {
            commit('updatePaymentId', id);
        },
        setDisabledButton({commit}, btn) {
            commit('updateDisabledButton', btn)
        },

        statusPoll({dispatch, commit}) {
            if(stopRequest) return;
            axios.request({
                method: 'POST',
                url: appConfig.prefix + 'status',
                headers: { 'Content-Type': 'application/json' },
                responseType: 'json',
                data: `{"payment_id": "${this.state.paymentId}" }`
            }).then(response => {
                console.log('Status check:', response.data);
                if(response.data &&
                    (response.data.status === appConfig.PURCHASE_ERROR ||
                    response.data.status === appConfig.PURCHASE_DECLINE ||
                    response.data.status === appConfig.PAYOUT_ERROR ||
                    response.data.status === appConfig.PAYOUT_DECLINE
                    )
                ) {
                    console.log('Operation rejected:', response.data.status);
                    commit('updateStateLoader', false);
                    commit('updateShowFrame', false);
                    commit('updateDisabledButton', false);
                    commit('updatePaymentId', null);
                    router.push({
                        name:'app-error'
                    })
                    return;
                }

                if(response.data && response.data.status === appConfig.AWAITING_3DS_RESULT && !this.state.showFrame) {
                    // console.log('Awaiting 3ds:', response.data.status); // Insecure !!
                    commit('updateStateLoader', true);
                    commit('updateShowFrame', true);
                    if(response.data.redirect_info.method && String(response.data.redirect_info.method).toUpperCase() !== 'POST') {
                        // console.log('Submitting redirect FORM:', response.data); // Insecure !!
                        // document.querySelector('#acs-iframe').setAttribute('src', response.data.redirect_info.acs_url);
                        document.querySelector('#acs-iframe').setAttribute('method', response.data.redirect_info.method);
                        Utils.setDataInAcsFormAndSubmit(response.data.redirect_info);
                    } else {
                        // console.log('Submitting ACS POST FORM:', response.data)
                        Utils.setDataInAcsFormAndSubmit(response.data.redirect_info);
                    }

                } else if (response.data && response.data.status === appConfig.AWAITING_PURCHASE_AFTER_3DS_PROCESSING) {
                    commit('updateShowFrame', false);
                } else if (response.data && response.data.status === appConfig.SUCCESS_PURCHASE) {
                    console.log(appConfig.SUCCESS_PURCHASE)
                } else if(response.data && response.data.status === appConfig.SUCCESS_TRANSFER) {
                    console.log('Operation success:', response.data.status);
                    commit('updateShowFrame', false);
                    commit('updateStateLoader', false);
                    commit('updateDisabledButton', false);
                    commit('updatePaymentId', null);
                    router.push({
                        name:'app-result'
                    })
                    return;
                }
                setTimeout(()=>dispatch('statusPoll'), appConfig.requestDelay)
            })
                .catch((error)=> {
                    setTimeout(()=>dispatch('statusPoll'), appConfig.requestDelay)
                    console.error(error.response.data);
                });

        }
    },
    getters: {
        getStateLoader(state) {
            return state.showLoader
        },

        getShowFrame(state) {
            return state.showFrame
        },

        getDisabledButton(state) {
            return state.disabledButton
        },
        getPaymentId(state) {
            return state.paymentId
        },

        getValidationError(state) {
            return state.validationError
        },

        getFee(state) {
            return state.fee
        }

    },
    modules: {

    }
});
